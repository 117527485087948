import {Bootstrap} from 'Bootstrap/Bootstrap';
import 'tilt.js';

class Home extends Bootstrap {

    DOMReady() {

    }

}

new Home();
